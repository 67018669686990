import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const CalendarPage = ({ data }) => (
  <div className="pageContent calendar--centered">
    <SEO
      title="Calendar"
      keywords={[`Sioux Lookout`, `hockey`, `Calendar`, `Schedule`, `info`]}
    />
    <h1 className="base__pageTitle">Calendar</h1>
    <div className="calendar__container calendar__monthView">
      <iframe
        title="SLMHA Calendar (month view)"
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FWinnipeg&amp;src=YXBvcHNtYzBnZWlvdHJrZnFpbGkzdDF2b2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23B39DDB&amp;showCalendars=0"
        style={{ borderWidth: 0 }}
        frameBorder="0"
        scrolling="no"
        className="calendar--responsive"
      ></iframe>
    </div>
    {/* display list view by default on mobile */}
    <div className="calendar__container calendar__listView">
      <iframe
        title="SLMHA Calendar (list view)"
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FWinnipeg&amp;src=YXBvcHNtYzBnZWlvdHJrZnFpbGkzdDF2b2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%233F51B5&amp;mode=AGENDA"
        style={{ borderWidth: 0 }}
        frameBorder="0"
        scrolling="no"
        className="calendar--responsive"
      ></iframe>
    </div>
  </div>
)

export default CalendarPage

export const pageQuery = graphql`
  query calendarQuery {
    calendarImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
